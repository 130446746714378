const fowardingAgentData = [
    {
        label: "Vendemmia",
        value: "Vendemmia",
    },
    {
        label: "Despachante 1",
        value: "Despachante 1",
    },
    {
        label: "Despachante 2",
        value: "Despachante 2",
    },
    {
        label: "Despachante 3",
        value: "Despachante 3",
    },
];

export default fowardingAgentData;